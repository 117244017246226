import { getDefaultLayoutProps } from '@ori-appshell/layouts/server';
import type { UserSegmentationQueryParams } from '@ori-appshell/rendering';
import { parseTenantLanguageParams, parseUserSegmentationParams } from '@ori-appshell/rendering';
import type { CategoryPageContainerProps } from '@ori/editorial-category';
import {
  CategoryPageContainer,
  defaultTranslations as categoryDefaultTranslations,
  translationsNamespace as categoryTranslationsNamespace,
} from '@ori/editorial-category';
import { getEditorialCategoryOrPageData } from '@ori/editorial-fetching';
import type { EditorialPageContainerProps } from '@ori/editorial-page';
import {
  EditorialPageContainer,
  defaultTranslations as editorialPageDefaultTranslations,
  translationsNamespace as editorialPageTranslationsNamespace,
} from '@ori/editorial-page';
import { olapicDefaultTranslations, olapicTranslationsNamespace } from '@ori/olapic-lib';
import { productListTranslationsNamespace, productListDefaultTranslations } from '@ori/product-list';
import type { GetStaticPaths, GetStaticProps, GetStaticPropsResult, NextPage } from 'next';
import { PageFeatures } from '~/common-data/PageFeatures';
import { MISSING_PAGE_ID_PARTS_MESSAGE } from '~/constants';
import { PACKAGE_NAME } from '~/constants-generated';
import { defaultNamespacedTranslations } from '~/constants/translationKeys';
import { Layout, type LayoutProps } from '~/containers/Layout';
import { campaignsMainLogger } from '~/logger';
import type { PreviewQueryParams } from '~/utils';
import { getEditorialPageId, getRevalidateInterval, getStaticPropsError, parsePreviewParams } from '~/utils';

const editorialTranslations = {
  [editorialPageTranslationsNamespace]: editorialPageDefaultTranslations,
  [categoryTranslationsNamespace]: categoryDefaultTranslations,
  [olapicTranslationsNamespace]: olapicDefaultTranslations,
  [productListTranslationsNamespace]: productListDefaultTranslations,
  ...defaultNamespacedTranslations,
};

type PageQueryParams = UserSegmentationQueryParams &
  PreviewQueryParams & {
    pageIdParts: string[];
  };

type EditorialPageTranslations = typeof editorialTranslations;

type EditorialsPageProps = {
  layoutPage: LayoutProps<EditorialPageTranslations>;
} & (
  | {
      type: 'category';
      props: Omit<CategoryPageContainerProps, 'translations'>;
    }
  | {
      type: 'page';
      props: Omit<EditorialPageContainerProps, 'translations'>;
    }
);

const logger = campaignsMainLogger.createChild('getStaticProps');

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
});

export const getStaticProps: GetStaticProps<Partial<EditorialsPageProps>, PageQueryParams> = async ({ params }) => {
  try {
    const { language, tenant } = parseTenantLanguageParams(params);
    const { atpClusterId, customerTypeId } = parseUserSegmentationParams(params);
    const { preview } = parsePreviewParams(params);
    const { pageIdParts } = params ?? {};

    if (pageIdParts) {
      const pageId = getEditorialPageId(pageIdParts);

      const { servicesUrls, frontendToken, layoutPage } = await getDefaultLayoutProps<EditorialPageTranslations>({
        pageId,
        language,
        tenant,
        sourceAppName: PACKAGE_NAME,
        defaultTranslations: editorialTranslations,
        pageFeatures: Object.values(PageFeatures),
      });

      const { breadcrumbs, editorialCategoryOrPageData, hideRating } = await getEditorialCategoryOrPageData({
        graphQlUrl: servicesUrls.graphQlNet.backendUrl,
        staticApiUrl: servicesUrls.staticApi.baseUrl,
        language,
        tenant,
        token: frontendToken,
        applicationName: PACKAGE_NAME,
        atpClusterId,
        customerTypeId,
        pageId,
      });

      const {
        editorialLandingPage,
        editorialLandingPages = [],
        editorialPage,
      } = editorialCategoryOrPageData.application ?? {};

      if (editorialLandingPage != null) {
        const resultProps: GetStaticPropsResult<EditorialsPageProps> = {
          props: {
            layoutPage,
            props: {
              breadcrumbs,
              data: { editorialLandingPage, editorialLandingPages },
              preview,
            },
            type: 'category',
          },
          revalidate: getRevalidateInterval(preview),
        };

        return resultProps;
      }

      if (editorialPage != null) {
        const resultProps: GetStaticPropsResult<EditorialsPageProps> = {
          props: {
            layoutPage,
            props: {
              breadcrumbs,
              data: { editorialLandingPages, editorialPage },
              hideRating,
              atpClusterId,
              customerTypeId,
              preview,
            },
            type: 'page',
          },
          revalidate: getRevalidateInterval(preview),
        };

        return resultProps;
      }
    }
    logger.warn(MISSING_PAGE_ID_PARTS_MESSAGE, { pageIdParts });

    return { notFound: true, revalidate: getRevalidateInterval(preview) };
  } catch (error) {
    throw new Error(getStaticPropsError(logger, error));
  }
};

const Page: NextPage<EditorialsPageProps> = ({ layoutPage, props, type }) => {
  const { breadcrumbs } = props;
  if (type === 'category') {
    const { data, preview } = props;

    return (
      <Layout layoutPage={layoutPage}>
        <CategoryPageContainer
          breadcrumbs={breadcrumbs}
          data={data}
          preview={preview}
        />
      </Layout>
    );
  }
  const { data, hideRating, atpClusterId, customerTypeId, preview } = props;

  return (
    <Layout layoutPage={layoutPage}>
      <EditorialPageContainer
        breadcrumbs={breadcrumbs}
        data={data}
        hideRating={hideRating}
        atpClusterId={atpClusterId}
        customerTypeId={customerTypeId}
        preview={preview}
      />
    </Layout>
  );
};

export default Page;
